import { MapBox } from '../MapBox'
import React, { useCallback, useEffect } from 'react'
import { Center, Flex, Spinner } from '@chakra-ui/react'
import { MapPin } from '../MapPin'
import { isEmpty } from 'lodash';
import { useAuthentication } from '../../hooks/useAuthentication'
import { UnauthorizedInfo } from '../UnauthorizedInfo'
import { useSelector } from 'react-redux'
import { apiConfig } from '../../api.config'

export const EntityMap = (
  { data, isLoading, isActivePopover, initialViewState, isHideAuth }
) => {
  const { isAuthenticated } = useAuthentication()
  const [list, setList] = React.useState([])
  const { token } = useSelector(state => state.profile.authentication)

  const getImage = useCallback(async (id) => {
    try {
      const response = await fetch(`${apiConfig}/image/download/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      const blob = await response.blob();
      const base64Data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

      return base64Data;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  }, [token]);

  useEffect(() => {
    const mappingList = async () => {
      let result = [];
      if (!isEmpty(data)) {
        for (const point of data) {
          const list = await Promise.all(
            point?.list.map(async (item) => ({
              ...item,
              imageData: item.imageData?.id ? await getImage(item.imageData.id) : null
            }))
          );

          const pointData = {
            ...point,
            list,
          };
          result = [...result, pointData];
        }
      }
      setList(result);
    };

    mappingList();
  }, [data]);

  return (
    <Flex
      minH="500px"
      height="calc(100% - 80px)"
      width="100%"
      flexDirection="column"
      flex={1}
      position="relative"
    >
      {!isAuthenticated && !isHideAuth && (
        <UnauthorizedInfo.Document
          borderWidth={0}
          direction="row"
          justifyContent="center"
        />
      )}
      {isLoading && (
        <Center
          position="absolute"
          zIndex={2}
          left="20px"
          top="20px"
          boxSize="30px"
          backgroundColor="blackAlpha.500"
          borderRadius="full"
        >
          <Spinner color="white" size="sm"/>
        </Center>
      )}
      <MapBox initialViewState={initialViewState}>
        {!isEmpty(list) && list?.map(item => (
          <MapPin
            isActivePopover={isActivePopover}
            key={item?.id}
            {...item}
          />
        ))}
      </MapBox>
    </Flex>
  )
}
