import { useState, useEffect } from 'react'
import axios from 'axios'
import { api } from '../../api'
import { residentType } from '../../constants/residentType'

const useDaData = (query) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!query.trim()) {
      setData(null)
      setLoading(false)
      setError(null)
      return
    }

    const fetchData = async () => {
      setLoading(true)
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party'
      const token = '729b21efbde6763624c5416de4690e2f4027d20f'

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ query }),
      }

      try {
        const response = await fetch(url, options)
        /*const response = await api.accounts.hints({
          query,
          resident: residentType.russianFederation
        })*/
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        setData(result)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [query])

  return { data, loading, error }
}

export default useDaData
