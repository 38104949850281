export const residentType = {
  undefined: 'Undefined',
  russianFederation: 'RussianFederation',
  belarus: 'Belarus'
}

export const residentLabel = {
  [residentType.undefined]: 'Другая страна',
  [residentType.russianFederation]: 'Российская Федерация',
  [residentType.belarus]: 'Республика Беларусь',
}

export const residentOptions = [
  {
    label: residentLabel[residentType.russianFederation],
    value: residentType.russianFederation
  },
  {
    label: residentLabel[residentType.belarus],
    value: residentType.belarus
  },
  {
    label: residentLabel[residentType.undefined],
    value: residentType.undefined
  },
]
