import { Placemark } from '@pbe/react-yandex-maps'
import { useMemo, useRef, useState } from 'react'

export const MapPin = ({ latitude, longitude, list, isDraggable, to, onDragEnd, isActivePopover }) => {
  const ref = useRef()

  const [isOpen, setIsOpen] = useState(false)

  const handleDragEnd = () => {
    onDragEnd(ref.current.geometry._coordinates)
  }

  const balloonContent = useMemo(() => {
    const _list = list
      ?.map((item) => {
        return `
          <div style='line-height: 1.6; display: flex'>
            ${item?.imageData ? `<img src='${item.imageData}' style='border-radius: 4px; width: 50px; height: 50px; object-fit: cover; margin-top: 6px; margin-right: 10px' />` : ''}
            <div>
              <div style='font-weight: bold; font-size: 1rem'>${item.title}</div>
              ${item.contragentName ? `<div style='opacity: 0.5'>${item.contragentName}</div>` : ''}
              <a href='${item.to}' style='color: #3182ce' target='_blank'>Подробнее</a>
            </div>
          </div>
        `
      })
      .join('')
    return `
    <div style='display: flex; flex-direction: column; gap: 10px'>
      ${_list}
    </div>
    `
  }, [list])

  if (isActivePopover) {
    return (
      <Placemark
        geometry={[latitude, longitude]}
        properties={{
          balloonContent,
        }}
        options={{
          draggable: isDraggable,
          balloonPanelMaxMapArea: 0,
        }}
        onClick={() => setIsOpen(!isOpen)}
        modules={['geoObject.addon.balloon']}
        instanceRef={(ref) => {
          if (ref && isOpen) {
            ref.balloon.open()
          } else if (ref) {
            ref.balloon.close()
          }
        }}
        onDragEnd={handleDragEnd}
      />
    )
  }

  return (
    <Placemark
      instanceRef={ref}
      geometry={[latitude, longitude]}
      onDragEnd={handleDragEnd}
      options={{
        draggable: isDraggable,
      }}
      {...(to && {
        onClick: () => window.open(String(to)),
      })}
    />
  )
}
